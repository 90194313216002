<template>
  <section class="login-page">
    <div class="login-title">TUTU签证服务平台-管理端</div>
    <div class="login-content">
      <div class="content-form">
        <h1>用户登录</h1>
        <div class="form-box">
          <a-form :form="form">
            <a-form-item>
              <a-input v-model="form.username" placeholder="请输入登录帐号" size="large">
                <app-icon type="icon-zhanghaodenglu" slot="prefix"></app-icon>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input-password v-model="form.password" placeholder="请输入登录密码" size="large">
                <app-icon type="icon-mima" slot="prefix"></app-icon>
              </a-input-password>
            </a-form-item>
            <a-form-item>
              <a-input v-model="form.code" placeholder="请输入图形验证码" size="large" class="code-input">
                <template #suffix>
                  <auto-img :src="codeSrc" class="code-img" @click.native="loadCode"></auto-img>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item class="err-msg-form">
              <div class="err-msg">{{ errMsg }}</div>
            </a-form-item>
            <a-form-item>
              <a-button @click="handleSubmit" :loading="loading" class="login-btn">登录</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      form: {
        username: '',
        password: '',
        code: '1234',
      },

      errMsg: '',
      codeSrc: '',
      loading: false,
    }
  },
  mounted() {
    this.loadCode()
  },
  methods: {
    resetError() {
      this.errMsg = ''
    },
    checkInput() {
      const { username, password, code } = this.form

      if (!username) {
        this.errMsg = '请输入账号'
        return false
      }

      if (!password) {
        this.errMsg = '请输入密码'
        return false
      }
      if (!code) {
        this.errMsg = '请输入验证码'
        return false
      }
      return true
    },
    async handleSubmit(e) {
      if (!this.checkInput()) {
        return false
      }
      this.loading = true
      try {
        const res = await this.$store.dispatch('Login', this.form)
        await this.loginSuccess(res)
      } catch (e) {
        this.requestFailed(e)
      }
      this.loading = false
    },
    async loginSuccess(res) {
      const toPath = decodeURIComponent(this.$route.query.redirect || '/')
      setTimeout(() => {
        this.$notification.success({
          message: '登陆成功',
          description: `欢迎回来`,
        })
      })
      await this.$router.replace(toPath).catch((e) => {
        // console.log(e)
      })
    },
    requestFailed(err) {
      this.errMsg = err.message
    },
    async loadCode() {
      // const res = await this.$axios.get('/user-web-api/admin/auth/getVerify')
      // this.codeSrc = res.data.picBase64Str
    },
  },
}
</script>
<style lang="less" scoped>
.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.login-title {
  font-size: 50px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-bottom: 69px;
}
.login-content {
  width: 1300px;
  height: 650px;
  position: relative;
  .content-form {
    width: 450px;
    height: 100%;
    position: absolute;
    right: 50px;
    h1 {
      font-size: 30px;
      font-weight: 600;
      color: #333333;
      text-align: center;
      margin: 60px 0;
    }
    .form-box {
      ::v-deep .ant-input-prefix {
        font-size: 26px;
      }
      ::v-deep .ant-input {
        border-radius: 40px;
        height: 50px;
        padding-left: 45px;
        font-size: 20px;
      }
      ::v-deep .ant-input-password {
        .ant-input-suffix {
          right: 20px;
          .anticon {
            font-size: 20px;
          }
        }
      }
      ::v-deep.code-input {
        .ant-input {
          padding-left: 20px;
        }
        .ant-input-suffix {
          height: 45px;
          width: 110px;
          display: flex;
          padding: 4px;
          align-items: center;

          margin-right: -10px;
          .code-img {
            width: 100%;
            height: 100%;
            background: skyblue;
            border-radius: 30px;
          }
        }
      }
      .err-msg-form {
        position: relative;
        top: -20px;
        margin-bottom: 0;
        .err-msg {
          padding: 0 20px;
          color: red;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
        }
      }
      .login-btn {
        height: 50px;
        width: 100%;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 48px;
        background: linear-gradient(90deg, #fd7163, #ffb940);
        border: 0 !important;
      }
    }
  }
}
</style>
